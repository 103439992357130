import React from 'react';
// import Button from '@mui/material/Button';
import { FaShareSquare } from "react-icons/fa"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions, Divider } from '@mui/material';

function DialogModal({ data, text }) {
    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState('paper');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <div>
                <button onClick={handleClickOpen} className='w-full text-xs uppercase p-1'>
                    Show more
                </button>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    scroll={scroll}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                     <DialogTitle id="alert-dialog-title" className='flex flex-wrap justify-between'>
                        <h5 className="text-2xl font-semibold bg-red">
                        {data.name}
                        </h5>
                        <div className='text-primary'>
                            <a href={data.link}
                            target="_blank" disabled="disabled"
                            className='text-xs font-semibold'>
                            <p className={"text-[12px] mt-2 py-1 px-5 mx-auto border font-bold rounded"}
                            >
                                <FaShareSquare className="inline mr-2" />
                                {'view project'}
                            </p>
                            </a>
                        </div>
                    </DialogTitle>
                    <DialogContent dividers>
                        <DialogContentText >
                             <p className='mb-3 text-sm'>{data.details}</p>

                             <Divider />

                            {/* <ImageList sx={{ width: 500, height: 450 }} cols={1} rowHeight={164}> */}
                            <span className=' flex flex-wrap justify-center mt-3'>
                            {data.images.map((item) => (
                                    // <ImageListItem key={item.image}>
                                    <img
                                        className="w-74 relative p-1 m-1 border"
                                        src={`${item.image}`}
                                        alt={item.title}
                                    />
                                    // </ImageListItem>
                                ))}
                            </span>
                                
                            {/* </ImageList>  */}

                            
                            {/* <Card sx={{ maxWidth: 545 }}>
                                <CardActionArea>
                                {data.images.map((item) => (
                                    <CardMedia
                                    component="img"
                                    height="140"
                                    image={item.image}
                                    alt="green iguana"
                                    />
                                ))}
                                    <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        Lizard
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Lizards are a widespread group of squamate reptiles, with over 6,000
                                        species, ranging across all continents except Antarctica
                                    </Typography>
                                    </CardContent>
                                </CardActionArea>
                                </Card> */}
                        </DialogContentText>
                    </DialogContent>
                    {/* <DialogActions>
                        <Button onClick={handleClose}>Disagree</Button>
                        <Button onClick={handleClose} autoFocus>
                            Agree
                        </Button>
                    </DialogActions> */}


                </Dialog>
            </div>
        </>
    );
}

export default DialogModal;

