import React from 'react';
import logo from '../../assets/images/me2.png';
import PrimaryButton from '../Button/PrimaryButton';

function About() {
    return (
        <div className='grid grid-cols-1 md:grid-cols-2 text-center md:text-left border-b-2 py-5'>
            <div className=''>
               <img src={logo} className="mx-auto" alt="Me" />
            </div>
            <div className='mb-5 my-auto'>
                <p className='text-6xl my-2 font-bold'>Frontend Web Developer</p>
                <p className='mb-5 text-base text-textBody'>
                    Hi, I am Naja Nadhirah as a front-end web developer focused on crafting clean & userfriendly experiences. I am passionate about building excellent websites that improves the lives around me.
                </p>
                <PrimaryButton className='rounded-full' text="View Portfolio"  url="/portfolio" />
            </div>
        </div>
    );
}

export default About;