import React from 'react';
// import './App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import NavBar from './components/navbar';
import Footer from './components/footer';
import Home from './components/Home/index';
import Portfolio from './components/portfolio';
import About from './components/About/index';
import ContactUs from './components/contact';

function App() {
  return (
    <React.Fragment>
      <NavBar />
      <main className='container'>
          <Routes>
          {/* <Route path="/" element={<Navigate to="/home" />} /> */}
            <Route path="/" element={<Home />} />
            {/* <Route path="/movies/:id" element={<MovieForm />} /> */}
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/about" element={<About />} />
            <Route path="/contactUs" element={<ContactUs />} />
          </Routes>
        </main>
        <Footer />
    </React.Fragment>

  );
}

export default App;
