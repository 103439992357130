import React from 'react';
import PrimaryButton from './../Button/PrimaryButton';
// import {FaLocationArrow} from "react-icons/fa"

function ContactUs() {
    return (
        <div className='border-b-2 p-5'>
            <div className='border  rounded border-primary my-4 p-8 md:py-8 text-center'>
                <p className='md:text-base'>Find something interesting?</p>
                <p className='text-3xl my-2 font-bold uppercase'>Let's Chat</p>
                <p className='text-base mb-5 text-textBody'>Let me help you to get your project started. Start your project 100% risk free, and get Free Consultation.</p>
                <PrimaryButton text="Contact Now" url="/contactUs" />
            </div>
        </div>
        
    );
}

export default ContactUs;