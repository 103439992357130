import React from 'react';
import PrimaryButton from './Button/PrimaryButton';
import {FaMap, FaRegEnvelope, FaPhoneAlt, FaWhatsapp} from "react-icons/fa"

const ContactUs = () => {
    return (
        <div>
            <div className='border-b-2 py-5 text-center border-gray-100'>
                <p className='text-4xl my-2 font-bold capitalize'>Quick Contact Address</p>
                <span className='text-base text-textBody'>Do you have a question about my services? Let’s start communication with me via email, phone call or WhatsApp.</span>
            </div>

            <div className='grid md:grid-cols-2 gap-x-36 sm:grid-cols-1 text-center md:text-left border-b-2 my-4'>
                <div className=''>
                    <p className='text-md font-bold'>Let's get in touch</p>
                    <span className='text-sm text-textBody'>I enjoy discussing new projects and design challenges. Please share as much info, as possible so we can get the most out of our first catch-up.</span>
                    <div className='grid grid-cols-2 gap-4 mt-5'>
                        <p className='text-base border rounded border-primary box-shadow p-3'>
                            <FaMap className="inline mr-2"/>Address:
                            <p>Ara Damansara, Selangor.</p>
                        </p>
                        <p className='text-base border rounded border-primary box-shadow p-3'>
                            <FaRegEnvelope className="inline mr-2"/>Email:
                            <p className='break-words'>najanadhirah12@gmail.com</p>
                        </p>
                    </div>
                    <div className=' justify-center flex mt-4 '>
                    <p className='text-base w-1/2 border rounded border-primary box-shadow p-3'>
                            <FaPhoneAlt className="inline mr-2"/>Phone Number:
                            <p >
                                <span className='mr-5'>+6019-8195549</span>
                                <a href='https://contactNaja.wasap.my' target='_blank' className='flex justify-end' rel="noreferrer"><button className='border bg-[#008900] border-[#008900] px-2 text-[10px] rounded text-black box'><FaWhatsapp className="inline mr-1" />Whatsapp</button></a>
                            </p>
                        </p>
                    </div>
                </div>
                <div className='mb-5 my-auto text-right p-5 bg-[#48484838]'>
                    <div className='flex my-2'>
                        <span className="inline-flex items-center px-3 text-sm min-w-lg text-[black] bg-[#ececec] rounded-l-md">
                            Name
                        </span>
                        <input type="text" id="website-admin" className="rounded-none rounded-r-lg bg-gray-50  text-[black] block flex-1 min-w-0 w-full text-sm p-2.5" placeholder="Elonmusk" />
                    </div>
                    <div className='flex my-2'>
                        <span className="inline-flex items-center px-3 text-sm min-w-fit text-[black] bg-[#ececec] rounded-l-md">
                            Email
                        </span>
                        <input type="text" id="website-admin" className="rounded-none rounded-r-lg bg-gray-50 text-[black] block flex-1 min-w-0 w-full text-sm p-2.5" placeholder="Email@gmail.com" />
                    </div>
                    <div className='flex my-2'>
                        <span className="inline-flex items-center px-3 text-sm min-w-fit text-[black] bg-[#ececec] rounded-l-md">
                            Subject
                        </span>
                        <input type="text" id="website-admin" className="rounded-none rounded-r-lg bg-gray-50 text-[black] block flex-1 min-w-0 w-full text-sm p-2.5" placeholder="Change Header Name" />
                    </div>
                    <div className='flex my-2'>
                        <span className="inline-flex items-center px-3 text-sm min-w-fit text-[black] bg-[#ececec] rounded-l-md">
                            Message
                        </span>
                        <textarea id="website-admin" className="rounded-none rounded-r-lg bg-gray-50 text-[black] block flex-1 min-w-0 w-full text-sm p-2.5" rows="4" cols="50" placeholder="Help me to change header name " />
                    </div>
                    <PrimaryButton className='rounded-full' text="Submit" url="/" />
                </div>
            </div>
        </div>

    )
}

export default ContactUs;