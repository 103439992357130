import React from 'react';

function Skills() {
    return (
        <div className='mt-14 border rounded-md p-4 border-primary box-shadow '>
            <h3 className='text-center font-bold mb-5 md:mb-12 w-2/6 mx-auto'>Techinal Skills</h3>
            <div className='text-sm grid grid-cols-1 md:grid-cols-2 gap-5 text-center pb-4'>
                <div>
                    <div className='flex justify-between font-semibold'>
                        <span>HTML / CSS / JQuery</span>
                        <span>85%</span>
                    </div>
                    <div className="w-full pt-2 ">
                        <div className="">
                            <div className="bg-light relative h-4 w-full rounded-md bg-[#000]">
                                <div className="progress-bar progress-bar-striped absolute top-0 left-0 flex h-full w-11/12 items-center justify-center rounded-md text-xs font-semibold text-white">
                                85%
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className='flex justify-between font-semibold'>
                        <span>Design - Bootstrap 5 / Vuetify</span>
                        <span>80%</span>
                    </div>
                    <div className="w-full pt-2 ">
                        <div className="">
                            <div className="bg-light relative h-4 w-full rounded-md bg-[#000]">
                                <div className="progress-bar progress-bar-striped absolute top-0 left-0 flex h-full w-10/12 items-center justify-center rounded-md text-xs font-semibold text-white">
                                80%
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className='flex justify-between font-semibold'>
                        <span>Javascript - Vue.js / NuxtJs</span>
                        <span>70%</span>
                    </div>
                    <div className="w-full pt-2 ">
                        <div className="">
                            <div className="bg-light relative h-4 w-full rounded-md bg-[#000]">
                                <div className="progress-bar progress-bar-striped absolute top-0 left-0 flex h-full w-8/12 items-center justify-center rounded-md text-xs font-semibold text-white">
                                70%
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className='flex justify-between font-semibold'>
                        <span>Git - GitLab / Github</span>
                        <span>80%</span>
                    </div>
                    <div className="w-full pt-2 ">
                        <div className="">
                            <div className="bg-light relative h-4 w-full rounded-md bg-[#000]">
                                <div className="progress-bar progress-bar-striped absolute top-0 left-0 flex h-full w-10/12 items-center justify-center rounded-md text-xs font-semibold text-white">
                                80%
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className='flex justify-between font-semibold'>
                        <span>Database - MySql</span>
                        <span>60%</span>
                    </div>
                    <div className="w-full pt-2 ">
                        <div className="">
                            <div className="bg-light relative h-4 w-full rounded-md bg-[#000]">
                                <div className="progress-bar progress-bar-striped absolute top-0 left-0 flex h-full w-7/12 items-center justify-center rounded-md text-xs font-semibold text-white">
                                60%
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='flex justify-between font-semibold'>
                        <span>Microsoft Office - Word/Excel/Powerpoint</span>
                        <span>90%</span>
                    </div>
                    <div className="w-full pt-2 ">
                        <div className="">
                            <div className="bg-light relative h-4 w-full rounded-md bg-[#000]">
                                <div className="progress-bar progress-bar-striped absolute top-0 left-0 flex h-full w-11/12 items-center justify-center rounded-md text-xs font-semibold text-white">
                                90%
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                {/* <div class="progress-bar progress-bar-striped rounded-md w-1/2" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div> */}

            </div>
        </div>

    );
}

export default Skills;