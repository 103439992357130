import React from 'react';
import About from './about';
import Projects from './projects';
// import Services from './services';
import Stacks from './stacks';
import ContactUs from './contactUs';
// import imgDefault from '../../assets/images/default.png';

const Home = () => {
    return (
        <div>
            {/* <h1>Services</h1> */}
            <About />
            <Stacks />
            {/* <Services /> */}
            <Projects />
            <ContactUs />
        </div>
    )
}

export default Home;