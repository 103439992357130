import React from 'react';
import { FaBuilding, FaRegCalendarAlt } from "react-icons/fa"

import * as data from '../../data/education.json';
const school = data;
console.log(school);

function Education() {
    return (
        <div className='mt-14 border rounded-md p-4 border-primary box-shadow'>
            <h3 className='text-center font-bold mb-12 uppercase'>Education Level</h3>
            {school.education.map((object, i) =>
                <div key={i} className='text-sm text-left pb-4 border-b-2'>
                    <div className='mt-2'>
                        <div className='flex uppercase justify-between font-semibold'>
                            <span>{object.name}</span> 
                            <span className='text-xs'>{object.result}</span>
                        </div>
                        <div className="flex justify-between pt-1 ">
                            <div>
                                <p>
                                    <FaBuilding className="inline mr-2" />
                                    <span className='text-xs'>{object.location}</span>
                                </p>
                            </div>
                            <p className='flex justify-between'>
                                <FaRegCalendarAlt className='mr-2' />
                                <span className='text-xs'>{object.yearStart} - {object.yearEnd}</span>
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Education;