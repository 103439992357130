import React, { Component } from 'react';
import { Link } from "react-router-dom";

class PrimaryButton extends Component {

    render() {
        return (
            <div className='flex justify-center'>
                <Link
                    className='text-[beige] border btn rounded-md text-base bg-primary hover:bg-secondary hover:text-[#000]'
                    to={this.props.url}>{this.props.text}</Link>
            </div>
        );
    }


}
export default PrimaryButton;

