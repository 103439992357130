import React from 'react';
// import { Link, NavLink } from 'react-router-dom';
import '../../index.css'

import * as data from '../../data/stacks.json';
const arrayStacks = data;

function Services() {
    return (
        <div className='py-5 text-center border-b-2 border-gray-100'>
            <p className='text-4xl my-2 font-bold capitalize'>Technical Stacks</p>
            <span className='text-base text-textBody'>My go-to tools and technologies, which I use on every project.</span>

            <div className='flex flex-wrap justify-center my-3 text-sm'>
                {arrayStacks.technologies.map((tech, i) =>
                    <a href={tech.link} target="_blank" rel="noreferrer" key={i} className='box'>
                        <div className= 'p-2 border border-primary box-shadow m-2 w-56 min-h-[10rem] transition duration-150 ease-out hover:ease-in hover:border-secondary' key={i}>
                            <img src={`${tech.url}`} key={i} className="mx-auto w-24 m-2 " alt="default" />
                            <p className='font-bold mt-4'>{tech.name}</p>
                            {/* <Link to="#" className='text-xs font-semibold'>CLICK HERE</Link> */}
                        </div>
                    </a>
                )}
            </div>
        </div>
    );
}

export default Services;