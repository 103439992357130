import React from 'react';
import logo from '../../assets/images/me2.png';
import Projects from '../Home/projects';
import Education from './education';
import Skills from './skilss';
import WorkExperience from './workExperience';
import ContactUs from './../Home/contactUs';

const AboutPage = (props) => {
    return (
        <div>
        <div className='grid grid-cols-1 md:grid-cols-2 text-center md:text-left border-b-2'>
            <div className=''>
               <img src={logo} className="mx-auto mt-10" alt="Me" />
            </div>
            <div className='mb-5 my-auto text-center'>
                <p className='text-textBody'>Hello everybody, I am</p>
                <p className='text-4xl md:text-6xl text-primary my-2 font-bold'>NAJA NADHIRAH</p>
                {/* <p className='text-4xl my-2 font-bold'>FRONT-END DEVELOPER</p> */}
                <p className='mb-5 text-base text-textBody'>
                    An innovative Frontend Web Developer in building the responsive websites. Proficient in HTML, CSS, Javascript and MYSQL
                    plus modern frameworks like Vuejs and Reactjs. I am aspiring to join a
                    company with make usefull my knowledge and technical skills to excel as a Web Developer.
                </p>
            </div>
        </div>
        <Skills />
        {/* <div className='border-t-2 mt-14 w-1/2 my-auto text-center'></div> */}
        <div className='text-sm grid grid-cols-1 md:grid-cols-2 gap-5 text-center pb-4'>
        <WorkExperience />
        <Education />

        </div>
        <Projects />
        <ContactUs />
        </div>
    )
}

export default AboutPage;