import React from 'react';
import { FaBuilding, FaRegCalendarAlt } from "react-icons/fa"

import * as data from '../../data/workExperience.json';
const word = data;

function WorkExperience() {
    return (
        <div className='mt-14 border rounded-md p-4 border-primary box-shadow '>
            <h3 className='text-center font-bold mb-12 w-1/3 mx-auto uppercase'>Work Experience</h3>
            {word.company.map((object, i) =>
                <div key={i} className='text-sm text-center pb-4 border-b-2'>
                    <div className='mt-2'>
                        <div className='flex uppercase justify-between font-semibold'>
                            <span>{object.position}</span>
                            <span>{object.type}</span>
                        </div>
                        <div className="flex justify-between pt-1 ">
                            <div>
                                <p><FaBuilding className="inline mr-2" /><span className='text-xs'>{object.name}</span></p>
                                {/* <p><FaMapMarkedAlt className="inline mr-2" /><span className='text-xs'>{object.location}</span></p> */}
                            </div>
                            <p className='flex justify-between'>
                                <FaRegCalendarAlt className='mr-2' />
                                <span className='text-xs'>{object.yearStart} - {object.yearEnd}</span>
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default WorkExperience;