import React from 'react';
import { FaExpand } from "react-icons/fa";
import MyModal from "../components/Dialog/simpleDialog"
import DialogModal from "../components/Dialog/dialogModal"
import * as data from '../data/portfolio.json';
// import DialogModal from './Dialog/dialogModal';
const objects = data;
const lists = objects.portfolios[0].lists
const projects = objects.portfolios[1].projects

// const filterProjects = () => {
//     console.log('masuk')
// }

const Portfolio = (props) => {

    return (
        <div className='text-center border-b-2 py-5'>
            <h1 className='text-4xl font-bold'>My Past Projects</h1>
            <span className='text-base text-textBody'>Get to know the actual code quality I deliver and real experience about my expertise.</span>
            <div className='mt-8'>
                {lists.map((list, idx) => <button key={idx} className='m-1 text-sm py-1 border px-3 rounded-md'>
                    {list.name}
                </button>)}
            </div>

            <div className='flex flex-wrap justify-center mt-5'>
                {projects.map((project, i) =>
                    <div className='rounded border border-primary box-shadow m-2 w-64' key={i}>
                        <img src={`${project.images[0].image}`} className="services-img mt-2 mx-auto h-40 w-56" alt="default" />
                        <div className='relative h-36'>
                            <p className='font-bold text-base mt-2 uppercase min-h-[48px]'>{project.name}</p>
                            <div className='my-1 leading-5'>
                                {project.listLang.map((lang, id) =>
                                    <button key={id} className={"text-[12px] mx-1 border px-2 rounded-full"}>{lang}</button>
                                )}
                            </div>
                        </div>
                        <div className='mx-2 bottom-2 relative text-[12px] button border hover:box-shadow text-primary align-bottom'>
                            {/* <FaExpand className="inline m-2"></FaExpand> */}
                            {/* <MyModal className="" data={project} text="Show Details" /> */}
                            <DialogModal className="" data={project} text="show Details" />
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Portfolio;

/* <div className='flex flex-wrap justify-center mt-5'>
                {projects.map((project, i) =>
                    <div className='rounded border border-primary box-shadow m-2 w-64' key={i}>
                        <img src={`${project.images}`} className="services-img mt-2 mx-auto h-52 w-60" alt="default" />

                        <div className='relative h-44'>
                            <p className='font-bold text-base mt-2 uppercase'>{project.name}</p>
                            <div className='text-left'>
                                {project.listLang.map((lang, id) =>
                                    <button key={id} className={"text-[12px] mx-1 border px-2 rounded-full"}>{lang}</button>
                                )}
                            </div>
                                <div className='absolute w-full text-[12px] bottom-2 button border text-primary w-full my-auto align-bottom'>
                                    <FaExpand className="inline m-2"></FaExpand>
                                    <span className='font-bold'>Show Details</span>
                                </div>
                        </div>
                    </div>
                )}
            </div> */