import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import logo from '../assets/images/logo.png'

const Header = (props) => {
    return (
        <nav className="h-28 md:h-24 px-2 py-3 mb-3 h-100">
            <div className="fixed top-0 left-0 right-0 bg-[#001021] z-10 p-4 mx-auto flex flex-wrap items-center md:justify-between justify-center">
                <div className="w-full flex md:justify-between justify-center md:w-auto static">
                    <Link
                        className="text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase text-white"
                        to="/"
                    >
                        <img src={logo} className="w-14" alt="Logo"/>
                    </Link>
                </div>
                <div id="navbarNav" >
                    <ul className="flex flex-row md:ml-auto">
                        <li className="nav-item">
                            <NavLink className="nav-link active px-3 py-2 flex items-center text-sm font-bold leading-snug text-white hover:text-primary"
                                aria-current="page" to="/portfolio"
                            >
                                Portfolio
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link px-3 py-2 flex items-center text-sm font-bold leading-snug text-white hover:text-primary"
                                aria-current="page" to="/about"
                            >
                                About
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                className="px-3 py-2 flex items-center text-sm font-bold leading-snug text-white hover:text-primary"
                                to="/contactUs"
                            >
                                Contact Us
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Header;