import React from 'react';
import * as data from '../../data/projects.json';
const arrayProject = data;

function Projects() {
    return (
        <div className='border-b-2 py-5 text-center border-gray-100'>
            <p className='text-4xl my-2 font-bold capitalize'>Check out my work</p>
            <span className='text-base text-textBody'>Let visit my work to get the real experience about my expertise. It's FREE.</span>
            
            <div className='flex flex-wrap justify-center my-3 text-sm'>
                {arrayProject.projects.map((object, i) =>
                <a href='/portfolio' key={i} className='box'>
                    <div className='p-2 border border-primary box-shadow rounded m-2 w-56 min-h-[13rem] transition duration-150 ease-out hover:ease-in hover:border-secondary' key={i}>
                        <img src={`${object.image}`} key={i} className="mx-auto services-img h-[134px]" alt="default" />
                        <p className='font-bold mt-4'>{object.name}</p>
                    </div>
                </a>
                )}
            </div>
        </div>
    );
}

export default Projects;